@import "@/assets/styles/theme.sass";


















































.baseLayoutContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 100vh;

  header {
    flex-shrink: 0;
    position: sticky;
    top: 0;
    z-index: 1000; }

  .baseContent {
    flex: 1 0 auto;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: $content-vertical-padding-mobile $content-horizontal-padding-mobile baseunit(23.5);
    flex-direction: row;
    max-width: $base-content-max-width;
    position:relative {}
    margin: 0 auto baseunit(7);
    @include mq($ankylo) {
      padding: $content-vertical-padding $content-horizontal-padding baseunit(23.5);
      position:relative {} }

    .mainContent {
      min-height: baseunit(40);
      width: $main-content-width;
      position:relative {} } } }

.partnerLogo {
  height: baseunit(4.8);
  width: baseunit(16.2); }

#footer {
  float: left;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;
  margin-top: 0;
  position: absolute; }
