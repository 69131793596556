@import "@/assets/styles/theme.sass";























































































































.loanPaymentProgress {
  margin-top: baseunit(2.8); }

.paymentScheduleBlock {
  background-color: $glass-color-system-neutral-white;
  padding: baseunit(2.4) 0 0;
  border-radius: 4px;
  border: 1px solid $glass-color-system-neutral-grey-04;
  margin-top: baseunit(1.6);
  font-family: $fonts-public-sans-regular;
  color: $glass-color-system-neutral-grey-09; }

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 baseunit(2.4);

  .title {
    font-family: $font-family-galano-grotesque;
    @include font-size(20);
    line-height: 1.4;
    color: $color-abbey; }

  .download {
    display: flex;
    @include font-size(14, 16);

    svg {
      margin-right: baseunit(0.4); } } }

.note {
  margin: 0 baseunit(2.4);
  @include font-size(12, 18); }

.statusSelector {
  display: flex;
  align-items: center;
  margin: baseunit(2.4)  baseunit(2.4) 0;

  .label {
    @include font-size(16,20);
    color: $glass-color-system-neutral-grey-09;
    margin-right: baseunit(0.8);
    margin-left: auto; }

  .inputSelect {
    width: 100%;
    max-width: baseunit(21); } }

.scheduleTable {
  margin-top: baseunit(2.4); }
