@import "@/assets/styles/theme.sass";
































































































































.transactionHistory {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  border: 1px solid $glass-color-system-neutral-grey-04;
  background-color: $glass-color-system-neutral-white;
  font-family: $fonts-public-sans-regular;

  .place {
    padding: baseunit(2.4);

    .header {
      display: flex;
      line-height: 1.47;
      justify-content: space-between;

      .name {
        font-family: $fonts-public-sans-bold;
        display: flex;
        @include font-size(20, 28);
        color: $glass-color-system-neutral-grey-09; }

      .downloadLink {
        display: flex;
        @include font-size(14,16);
        padding-top: baseunit(0.5);
        width: baseunit(12.6);

        svg {
          color: $brand-link-partner-color;
          margin-right: baseunit(.4);
          width: baseunit(1.6);
          height: baseunit(1.6); } } }

    .noLoan {
      padding: baseunit(1.7) baseunit(12.7) baseunit(1.5);
      @include font-size(15);
      line-height: 1.47;
      text-align: center; } } }

.filters {
  display: flex;
  justify-content: space-between;
  margin-top: baseunit(2.8);

  .inputSearch {
    width: 100%;
    max-width: baseunit(42);


    ::v-deep .inputContainer {
      font-family: $fonts-public-sans-regular;
      color: $glass-color-system-neutral-grey-08;
      padding: baseunit(0.8) baseunit(1.2) baseunit(0.8) baseunit(3.2); } } }

.timeFilter {
  display: flex;
  align-items: center;

  .label {
    @include font-size(16,20);
    color: $glass-color-system-neutral-grey-09;
    margin-right: baseunit(0.8); }

  .inputSelect {
    min-width: baseunit(21); } }

.noResults {
  font-family: $fonts-public-sans-regular;
  color: $glass-color-system-neutral-grey-09;
  @include font-size(16,20);
  text-align: center;
  padding: baseunit(2) 0; }
