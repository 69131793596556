@import "@/assets/styles/theme.sass";










































#banner {
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1);
  width: 100%;
  background: linear-gradient(to right, $color-science-blue, $color-science-blue 50%, $color-arapawa 50%, $color-arapawa);
  padding: 0;
  margin: 0;

  .bannerContent {
    height: 100%;
    margin: 0 auto;
    padding: 0 $content-horizontal-padding;
    max-width: $content-width;
    display: flex;
    background: linear-gradient(69deg, $color-science-blue, $color-science-blue 32%, $color-arapawa 32%, $color-arapawa);
    justify-content: space-between;
    align-items: center;
    position: relative;

    .leftSide {
      padding: baseunit(4.5) $content-horizontal-padding;
      display: block;
      width: 27%;
      height: 100%;

      .completeSign {
        width: baseunit(5.7);
        height: baseunit(5.7);
        margin: 0 auto baseunit(1); }

      .label {
        @include font-size(30);
        font-family: $font-family-galano-grotesque;
        line-height: 1.27;
        color: $glass-color-system-neutral-white;
        text-align: center; } }

    .rightSide {
      padding: baseunit(3.2) $content-horizontal-padding baseunit(2);
      width: 65%;
      height: 100%;

      .heading {
        @include font-size(19);
        font-family: $font-family-galano-grotesque;
        line-height: 1.16;
        text-align: center;
        color: $glass-color-system-neutral-white;
        margin-bottom: baseunit(1.7);
        white-space: pre-line; } }

    .closeButton {
      position: absolute;
      right: baseunit(2);
      top: baseunit(2.3);
      @include mq($brachio) {
        left: calc(100% + #{baseunit(3.5)});
        top: baseunit(2.3); } } } }
