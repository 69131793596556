@import "@/assets/styles/theme.sass";


































































































































.applicationrenewalBanner {
  margin: baseunit(1.3) 0; }

.loanInformation {
  width: 100%;
  height: 100%;

  .summaryInfo {
    padding: baseunit(2.4);
    border-radius: 4px;
    border: 1px solid $glass-color-system-neutral-grey-04;
    background-color: $glass-color-system-neutral-white;
    color: $glass-color-system-neutral-grey-08;
    @include font-size(12,16);
    font-family: $fonts-public-sans-semibold;
    margin-top: baseunit(1.2);

    .header {
      font-family: $fonts-public-sans-bold;
      @include font-size(20,28);
      color: $glass-color-system-neutral-grey-09; }

    .balance {
      @include font-size(24,28);
      font-family: $fonts-public-sans-regular;
      color: $glass-color-system-pop-green-dark;
      margin-top: baseunit(1.2); }

    .loanProgressBar {
      margin-top: baseunit(2); }

    .table {
      border-collapse: collapse;
      margin-top: baseunit(2.8);
      width: 100%;
      color: $glass-color-system-neutral-grey-08;
      font-family: $fonts-public-sans-semibold;
      @include font-size(12,16);

      tbody {
        width: 100%;

        tr {
          width: 100%;
          border-bottom: 1px solid $glass-color-system-neutral-grey-06;

          &:last-child {
            border-bottom: none; }

          td {
            padding: baseunit(1.6) 0; }

          td:last-child {
            color: $glass-color-system-neutral-grey-09;
            font-family: $fonts-public-sans-regular;
            @include font-size(14,18);
            text-align: right; } } } } }

  .footerLabel {
    font-style: italic;
    font-weight: 100;
    color: $color-abbey;
    @include font-size(12,16); } }

.buttons {
  font-family: $fonts-public-sans-regular;
  @include font-size(16, 18);
  display: flex;
  align-items: center;

  .brandButton {
    height: baseunit(3.2);
    margin-right: baseunit(0.4); } }
