@import "@/assets/styles/theme.sass";


























































































.applicationRenewalBanner {
  margin-bottom: $glass-spacing-105; }

.documents {
  h2 {
    margin-bottom: $glass-spacing-2; }

  .loanList > * + * {
    margin-top: $glass-spacing-2; } }

.column {
  display: flex;
  flex-direction: column; }

.row {
  display: flex;
  align-items: center; }

.number {
  color: $color-system-neutral-gray-5;
  @include font-size(14,20);
  font-family: $fonts-public-sans-regular; }

.title {
  font-family: $fonts-public-sans-bold;
  @include font-size(20,28);
  color: $glass-color-system-neutral-grey-09; }

.titleIcon {
  width: baseunit(2.4);
  height: baseunit(2.4);
  margin-right: $glass-spacing-1;
  fill: $color-system-neutral-gray-5; }
