$heading-partner-font-family: 'Galano Grotesque', sans-serif !default;
$heading-partner-font-weight: bold !default;
$typography-font-family-display: 'Galano Grotesque', sans-serif !important;


// @font-faces:
@font-face {
  font-family: 'Galano Grotesque';
  src: url('~@/assets/fonts/GalanoGrotesque/GalanoGrotesque-Regular.otf') format("opentype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Galano Grotesque';
  src: url('~@/assets/fonts/GalanoGrotesque/GalanoGrotesque-Medium.otf') format("opentype");
  font-weight: 500;
  font-style: normal; }

@font-face {
  font-family: 'Galano Grotesque';
  src: url('~@/assets/fonts/GalanoGrotesque/GalanoGrotesque-MediumItalic.otf') format("opentype");
  font-weight: 500;
  font-style: italic; }

@font-face {
  font-family: 'Galano Grotesque SemiBold';
  src: url('~@/assets/fonts/GalanoGrotesque/GalanoGrotesque-SemiBold.otf') format("opentype");
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Galano Grotesque';
  src: url('~@/assets/fonts/GalanoGrotesque/GalanoGrotesque-SemiBoldItalic.otf') format("opentype");
  font-weight: 600;
  font-style: italic; }

@font-face {
  font-family: 'Galano Grotesque';
  src: url('~@/assets/fonts/GalanoGrotesque/GalanoGrotesque-Bold.otf') format("opentype");
  font-weight: bold;
  font-style: normal; }

@font-face {
  font-family: 'Galano Grotesque';
  src: url('~@/assets/fonts/GalanoGrotesque/GalanoGrotesque-BoldItalic.otf') format("opentype");
  font-weight: bold;
  font-style: italic; }
