@import "@/assets/styles/theme.sass";































































.simpleBanner {
  background-color: $color-arapawa;

  &.alert {
    background-color: $color-fire; }
  &.success {
    background-color: $color-secondary-accent-2; }
  .mintBannerContent {
    @include font-size(16);
    width: $content-width - 2 * $content-horizontal-padding;
    min-height: baseunit(6.4);
    padding: baseunit(1.4) 0;
    font-family: $font-family-galano-grotesque;
    line-height: 2.19;
    color: $color-gray;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $glass-color-system-neutral-white;

    .close {
      cursor: pointer;

      .svguse {
        width: baseunit(1.4);
        height: baseunit(1.4);
        color: $color-gray;
        stroke: $color-gray;
        stroke-width: 2; } } }

  .content {
    @include font-size(16);
    width: $content-width - 2 * $content-horizontal-padding;
    min-height: baseunit(6.4);
    padding: baseunit(1.4) 0;
    font-family: $font-family-galano-grotesque;
    line-height: 2.19;
    color: $glass-color-system-neutral-white;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $glass-color-system-neutral-white;

    .close {
      cursor: pointer;

      .svguse {
        width: baseunit(1.4);
        height: baseunit(1.4);
        color: $glass-color-system-neutral-white;
        stroke: $glass-color-system-neutral-white;
        stroke-width: 2; } } }

  &:last-child {
    .content {
      border-bottom-style: none; } } }

.extraPayment {
  margin: 0 auto;
  width: 61.5rem;
  padding-bottom: baseunit(1.4);
  font-family: $font-family-galano-grotesque; }

.extraPaymentBtn {
   border: 1px solid $glass-color-system-neutral-white;
   background: transparent; }
