@import "@/assets/styles/theme.sass";














































































































































































































@import '@/components/DateInput/_variables';

.validationError {
  margin-top: baseunit(); }

.confirmOneTimePaymentContainer {
  margin-top: baseunit(1.5); }

.detailsBox,
.header {
  margin: 0; }

.header {
  color: $glass-color-system-pop-grey-dark;
  @include font-size(28, 32); }

.actionButtons {
  margin-top: baseunit(); }

.paymentBox {
  margin-top: baseunit(2);
  padding: baseunit(3) baseunit(4.5);
  width: 100%;

  .paymentContent {
    margin: auto;

    .brandButton {
      width: baseunit(18);
      height: baseunit(5);
      line-height: baseunit(4.8);
      margin-left: baseunit(1); } } }

::v-deep .calendar {
  margin: baseunit(0.2) 0 baseunit(2); }

.paymentDate {
  ::v-deep .inputContainer {
    &.disabled {
      background-color: $color-porcelain;
      border: 1px solid $color-light-gray; }

    input {
      color: $color-oslo-gray; } } }
