@import '@/sass/core.sass';
@import '@/assets/styles/fonts.sass';
@import '@odx/customer-portal-ui-core/src/assets/styles/partner/_fonts.sass';
@import '@odx/ui-components/src/sass/core.sass';
@import '@odx/application-ui/src/assets/styles/theme.sass';
@import '@odx/ui-components/src/assets/styles/partner/_fonts';


$content-width: baseunit(102.4);
$sidebar-width: baseunit(24);
$content-horizontal-padding: baseunit(2);
$content-horizontal-padding-mobile: baseunit(1);
$content-vertical-padding: baseunit(2);
$content-vertical-padding-mobile: baseunit(1);
$main-content-width: 100%;
$base-content-max-width: baseunit(103.2) + 2 * $content-horizontal-padding;
$form-control-label-line-height: 1;
$body-font-family: 'Galano Grotesque', sans-serif !important;

// COLORS

// PRIMARY BRAND COLORS
$glass-color-brand-primary-dark-full: #000082;


// see http://chir.ag/projects/name-that-color/
$color-gray: #333333;
$color-porcelain: #ecedee;
$color-abbey: #53565a;
$color-oslo-gray: #97999b;
$color-science-blue: #006fcf;
$color-prussian-blue: #00175a;
$color-arapawa: #000082;
$color-prussian-blue: #00175a;
$color-deep-sea: #008767;
$color-iron: #d2d3d4;
$color-ebony: #0f1129;
$color-fire: #ff474a;
$color-brick: #a33f1f;
$color-beige: #fbefec;
$color-cerulean: #026fcf;
$color-pale-gray: #f7f8f9;
$color-light-gray: #c8c9c7;
$color-cerulean: #026fcf;
$color-french-pass: #afd4fd;
$color-black: #000000;
$color-alabaster: #f7f7f7;
$color-iron: #e0e0e1;
$color-alto: #dcdbdb;
$color-white: #ffffff;
$color-boulder: #7c7b7b;
$color-brownish-grey: #666666;
$color-concrete: #f2f2f2;
$color-mercury: #e5e5e5;
$color-new-midnight-blue: #0069c4;
$color-navy-blue: #0063b9;
$color-deep-blue: #000082;

// Colors from design system
$color-system-emphasis-hover: rgba($color-abbey, 0.08);
$color-system-emphasis-onclick: rgba($color-gray, 0.2);
$color-secondary-light-accessible-20: #cce2fc !default;

$primary-color: $color-science-blue;
$background-color: $color-system-neutral-gray-1;
$text-color: $color-gray;
$text-color-2: $color-abbey;

// HEADING
$h2-font-family: $fonts-galano-grotesque-bold;
$h2-color: $glass-color-system-pop-grey-dark;
$h4-font-family: $fonts-galano-grotesque-bold;
$h4-color: $glass-color-system-neutral-grey-09;

// FONTS
$font-family-galano-grotesque: 'Galano Grotesque', sans-serif;
$font-family-benton-sans: 'Galano Grotesque', sans-serif;
$font-family-helvetica-neue: 'Helvetica Neue', Helvetica, Arial, sans-serif;
$font-family-public-sans: 'Galano Grotesque', sans-serif !important;

$fonts-public-sans-semibold: 'Galano Grotesque SemiBold' !important;
$fonts-public-sans-bold: $fonts-galano-grotesque-bold !important;
$fonts-public-sans-regular: $font-family-galano-grotesque !important;


// BUTTONS
$button-font-weight: normal;
$button-border-radius: 4px;

// SPINNER
$spinner-track-border: 6px solid $color-pale-gray;
$spinner-carriage-border: 6px solid $color-science-blue;

// BRAND LINK
$brand-link-partner-color: $glass-color-brand-primary-dark-full;

// IDLE MODAL WINDOW
$idle-modal-background-color: rgba(51, 51, 51, 0.5);

$idle-modal-dialog-background: $glass-color-system-neutral-white;
$idle-modal-dialog-border-radius: 4px;
$idle-modal-dialog-border: 1px solid $color-boulder;
$idle-modal-dialog-padding: baseunit(3.7) baseunit(5.3) baseunit(4) baseunit(3);
$idle-modal-dialog-box-shadow: none;

$idle-modal-dialog-header-font-size: 20;
$idle-modal-dialog-header-font-weight: 400;
$idle-modal-dialog-header-color: $color-gray;
$idle-modal-dialog-header-line-height: 1.4;
$idle-modal-dialog-header-margin-bottom: baseunit(1.6);
$idle-modal-dialog-header-font-family: $font-family-galano-grotesque;

$idle-modal-dialog-body-font-size: 14;
$idle-modal-dialog-body-line-height: 1.36;
$idle-modal-dialog-body-color: $color-gray;

$idle-modal-dialog-body-margin-bottom: baseunit(3.5);
$idle-modal-dialog-body-font-family: $fonts-galano-grotesque;

$idle-modal-close-icon-top: baseunit(2);
$idle-modal-close-icon-right: baseunit(2);
$idle-modal-close-icon-width: baseunit(1.6);
$idle-modal-close-icon-height: baseunit(1.6);
$idle-modal-close-icon-color: $primary-color;
$idle-modal-close-icon-stroke: 1;

// DOCUMENT VIEWER
$document-viewer-header-color: $color-black;

// TEXT INPUT
$input-text-background-color-error: $glass-color-system-neutral-white;
$datepicker-input-error-background-color: $glass-color-system-neutral-white;
$input-text-placeholder-color: $glass-color-system-neutral-grey-08;

// DROP-ZONE
$dropzone-success-icon-partner-color: $color-primary-brand-light;

// INPUT SELECT
$input-select-padding: $glass-spacing-1 $glass-spacing-4 $glass-spacing-1 $glass-spacing-105;

//VALIDATION
$color-system-validation-error: #FF474A;
$color-system-validation-success: #0074ff;

//partner specific

.navigationMenu {
  ::v-deep {
    .userName {
      font-family: $font-family-galano-grotesque; } } }

#header {
  .headerContent {
    .brandBlock {
      .tabs {
        .tab {
          brand-button {
            font-family: $font-family-galano-grotesque; } } } } } }
