@import "@/assets/styles/theme.sass";































































































































































.termLoanItem {
  width: 100%;
  height: 100%;

  .body {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin: baseunit(1.2) baseunit(2.4);

    .title {
      font-family: $fonts-public-sans-bold;
      @include font-size(16,28);
      color: $glass-color-system-neutral-grey-09; }

    label {
      @include font-size(12, 16);
      color: $color-system-neutral-gray-5;
      font-weight: 600; }

    .value {
      @include font-size(12, 18);
      font-family: $fonts-public-sans-regular;
      text-overflow: ellipsis;
      overflow: hidden; }

    .row {
      display: flex;
      align-items: center; }


    .payment {
      display: flex;
      align-items: center;
      @include font-size(14, 16);

      svg {
        margin-right: baseunit(0.3); } }

    .dropdown {
      display: flex;
      flex-direction: row;

      .dropdownList {
        margin: 0;
        padding: 0;
        @include font-size(14, 16);
        color: $color-system-neutral-gray-5;
        font-weight: 500;
        font-family: $fonts-public-sans-regular;

        .dropdownItem {
          list-style-type: none;

          &+.dropdownItem {
            margin-top: baseunit(0.75); } } } }

    .column {
      padding: baseunit(1.6) 0;
      width: max-content;
      text-align: left;

      &+.column {
        margin-left: baseunit(3); } } }

  .paymentScheduled {
    display: flex;
    border-top: 1px solid $glass-color-system-neutral-grey-06;
    padding: baseunit(1.2) baseunit(2.4);
    font-family: $fonts-public-sans-regular;
    @include font-size(12, 20);
    color: $color-system-neutral-gray-5;

    .icon {
      margin-right: baseunit(1); } } }
