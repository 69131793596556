@import "@/assets/styles/theme.sass";




























































































$brand-footer-text-font: (font-size: 12, line-height: 18) !default;
$brand-footer-label-font: (font-size: 12, line-height: 16) !default;
$brand-footer-link-font: (font-size: 14, line-height: 18) !default;
$brand-footer-background-color: $glass-color-brand-primary-dark-full !default;

footer {
  background-color: $brand-footer-background-color;
  display: flex;
  justify-content: center;
  padding: baseunit(2) baseunit(2.4);
  color: $glass-color-system-neutral-white; }

.sidebar {
  width: 100%;
  letter-spacing: normal;
  font-style: normal;
  flex-direction: column;
  font-stretch: normal;
  line-height: 1.33;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  text-align: center;

  .section.leftSide {
    .description {
      margin-top: baseunit(2);
      @include font-size($brand-footer-text-font...);
      font-family: $fonts-public-sans-regular;
      max-width: baseunit(68.4); }

    .footnote {
      margin-top: baseunit(2.4); } }

  @include mq($tyranno) {
    text-align: left;
    flex-direction: row;
    padding: 0;

    .section {
      &.leftSide {
        flex: 2 2 50%;
        padding-right: $glass-spacing-3;
        border-right: 1px solid $color-system-neutral-gray-3; }
      &.rightSide {
        flex: 1 1 50%;
        margin-left: baseunit(5.6); } } } }

.rightSide {
  display: flex;
  flex-direction: column;

  .header {
    @include font-size(20,28);
    margin: 0; }

  .subtitle {
    @include font-size(12,18);
    margin-top: baseunit(1.2); }

  .workHours {
    @include font-size(12,18);
    font-family: $fonts-public-sans-regular;
    margin-top: baseunit(1.2);
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    column-gap: baseunit(0.8);
    max-width: baseunit(22); }

  .contacts {
    display: flex;
    margin-top: baseunit(1.6); }

  .contact {
    .label {
      @include font-size($brand-footer-label-font...);
      font-family: $fonts-public-sans-semibold;
      margin-bottom: $glass-spacing-025; }

    .link {
      @include font-size($brand-footer-link-font...);
      font-weight: bold; } }

  .contact + .contact {
    margin-left: $glass-spacing-4; } }

.link {
  color: $glass-color-system-neutral-white; }

.linkContainer {
  .brandLink {
    @include font-size(14, 20);
    font-family: $fonts-public-sans-bold;
    margin-right: baseunit(2.4); } }

.policyLink {
  text-decoration: underline;
  cursor: pointer;
  color: $glass-color-system-neutral-white; }
