@import "@/assets/styles/theme.sass";









































































































.renewalBanner {
  &:not(:last-of-type) {
    margin-bottom: $glass-spacing-105; } }

.reminder {
  background-color: $color-system-validation-success !important;
  color: $color-white-four !important; }

.bannerHeading {
  font-weight: bold; }

.bannerParagrapgh {
  &:not(:last-of-type) {
    margin-bottom: baseunit(); } }

.arrow {
  transform: rotate(180deg); }

.linkText {
  display: flex;
  align-items: baseline;
  gap: baseunit(); }

.link {
  color: $glass-color-system-neutral-white; }
